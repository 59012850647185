.btn-secondary {
    --bs-btn-color: #fff !important;
    --bs-btn-bg: #F66E06 !important;
    --bs-btn-border-color: #F66E06 !important;
    --bs-btn-hover-color: #F66E06 !important;
    --bs-btn-hover-bg: #fff !important;
    --bs-btn-hover-border-color: #F66E06 !important;
    --bs-btn-focus-shadow-rgb: 130,138,145;
    --bs-btn-active-color: #fff !important;
    --bs-btn-active-bg: #F66E06 !important;
    --bs-btn-active-border-color: #F66E06 !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff !important;
    --bs-btn-disabled-bg: #F66E06 !important;
    --bs-btn-disabled-border-color:#F66E06 !important;
}