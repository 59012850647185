 /* Media query */
 @media (max-width: 767px) {

.lastBannerSec {
    height: 300px !important;
}
.phoneImgHeight{
    height: 100%;
    max-height: 372px;
}
button.whatsappBtn {
    font-size: 24px !important;
}
.mainHeading {
    font-size: 19px !important;
    height: 55px !important;
}
.secHeading {
    font-size: 22px !important;
}
.registerSec.row {
    height: 45% !important;
}
.bannerStyle {
    background-image: url(/src/assets/images/MobileBg.jpg) !important;
    background-position: center;
    background-size: cover;
    height: 582px;
}
.logoImg {
    height: 5rem !important;
    width: 8rem !important;
}
.logInBtn.btn-secondary {
    font-size: 12px !important;
    padding: 5px 13px !important;
}
.langImg {
    height: 28px !important;
}
.inputStyle {
    height: 45px !important;
}
.HindiTextStyle {
    font-size: 28px !important;
}
button.ReadBtn {
    font-size: 15px !important;
}
.reasonSecRgt {
    background-image: none !important;
}
button.regBtns {
    font-size: 17px !important;
    padding: 5px 18px !important;
    margin: 15px 0px !important;
}
.topDivider {
    background-image: url(/src/assets/images/mobilePgBnr.png) !important;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
button.subSecLoginBtn {
    padding: 4px 10px !important;
    font-size: 13px !important;
}
 }

 /* media query end */
 .logoImg {
    height: 8rem;
    width: 14rem;
    object-fit: cover;
}
.langImg {
    height: 40px;
}
 .bannerStyle{
    background-image: url(/src/assets/images/BannerImg.jpg);
    background-position: center;
    background-size: cover;
    height: 582px;
}
.DownloadSection{
    background-image: url(/src/assets/images/download_img.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 623px;
}

.Downloadapp{
    background-image: url(/src/assets/images/downloadapp.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 623px;
}


.download_page{
    background-color: #F66E06;
}
.download_app{
    background-color: #fff;
}

.download_btn {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.download_btn:hover,
.download_btn:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.download_btn:hover {
  transform: translateY(-1px);
}

.download_btn:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.vertical-line {
    display: inline-block;
    border-left: 2px solid white;
    height: 20px;
    margin: 0 10px;
    vertical-align: middle;
}


.jainlogo {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Laila;
    font-weight: 600;
}
.HindiTextStyle{
    font-size: 35px;
    font-family: Tiro Devanagari Hindi;
    font-weight: 400;
}
.registerSec.row {
    width: 1200px;
    height: 150px;
    border-radius: 11px;
    background-image: url(/src/assets/images/TexturedBd2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.RegisterBtn {
    border: 0px !important;
    font-weight: 600 !important;
    background: linear-gradient(266deg, #FFD600 0%, #FF7A00 89.58%);
}  
.inputStyle {
    height: 56px;
    border-radius: 4px;
    border: 1px solid var(--Light-Black, #101010);
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.20);
    padding: 5px 10px;
}
button.ReadBtn {
    background: #fff;
    color: #f66e06;
    border-radius: 3px;
    border: 0px;
    font-size: 20px;
    font-weight: 500;
}
.logInBtn.btn-secondary {
    color: var(--Safron, #F66E06);
    border-radius: 5px;
    background: #FFF;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 20px;
}
.mainHeading {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Laila;
    font-size: 55px;
    font-weight: 700;
    background: linear-gradient(268deg, #FAFF00 1.38%, #FE8100 35.3%, #FBEE00 65.68%, #FF8A00 98.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    height: 70px;
}
.secHeading {
    color: #FFF;
    font-family: Laila;
    font-size: 50px;
    font-weight: 600;
}
.row.sliderSec{
    background-image: url(/src/assets/images/BG.png);
    background-repeat: repeat;
    background-position: center;
    background-size: contain;
}
button.regBtns {
    border: 2px solid var(--Safron, #F66E06);
    color: var(--Safron, #F66E06);
    text-align: center;
    font-size: 22px;
    padding: 5px 30px;
    margin: 20px 0px;
}
button.regBtns:hover {
    border: none;
    background-color: var(--Safron, #F66E06);
    color: #FFF;  
}
.contactUsStyle {
    position: relative;
    background-image: url(/src/assets/images/ContactBg.png);
    background-size: cover;
    background-repeat: no-repeat;
}
.whatsappStyle{
    background-image: url(/src/assets/images/Group44.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-top: 1px solid;
    border-bottom: 1px solid;
}
button.whatsappBtn {
    background: #29A71A;
    border: 0px;
    font-weight: 500;
    font-size: 38px;
}
button.whatsappBtn:hover {
    color: #29A71A !important;
    background: none;
    border: 2px solid #29A71A;
}
button.whatsappBtn:active {
    background: #29A71A !important;
    border: 0px !important;
    color: #fff !important;
}
.reasonSec{
    background-image: url(/src/assets/images/lefttextured.png);
    background-repeat: no-repeat;
    background-size: contain;
}
button.DonloadBtn {
    color: #fff;
    border: 2px solid #fff;
    font-size: 16px;
    font-weight: 500;
}
button.DonloadBtn:hover {
    color: #fff;
    background: none;
    border: 2px solid #fff;
}
.reasonSecRgt{
background-image: url(/src/assets/images/texturedRight.png);
background-repeat: no-repeat;
background-size: contain;
background-position: right;
}

.sliderSecStyle {
    border-radius: 6.198px;
    opacity: 0.75;
    background: #FFF;
    width: 290px;
    position: absolute;
    bottom: 10px;
    transform: translate(5px, 0px);
}
button.sendBtn {
    background: var(--Safron, #F66E06);
    border: 0px;
    color: #fff;
    font-size: 13px;
    display: flex;
    align-items: center;
}
button.ReadBtn:hover {
    border: 2px solid #fff !important;
    background: none !important;
    color: #fff;
    border-radius:5px !important;
    padding: 4.3px 10.5px !important;
}

.PageTitleText{
text-align: center;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
font-size: 40px;
font-family: 'Roboto';
font-weight: 500;
}
input.contactInputStyle{
    margin: 10px 0px;
    border: 0px;
    border-bottom: 1px solid;
    border-radius: 0px;
    box-shadow: none;
}
button.contactSubmit {
    font-size: 24px;
    font-weight: 500;
    padding: 0px 25px;
    border-radius: 0px;
}


.btn-1{
    background-color: #f66e06;
    font-size: 21px;
}

/* //////////Carousel//////////// */
ul {
    list-style-type: none;
    padding: 0;
}
a {
    text-decoration: none !important;
    color: #000 !important;
}
a.numberlinkstyle {
    color: #fff !important;
}
a.numberlinkstyle:hover {
    color: #fff !important;
}

a:hover {
    color: #F66E06 !important;
}
.RegisterBtn.btn:hover {
    color: #fff;
}
.topDivider {
    background-image: url(/src/assets/images/subPageBanner.png);
    background-position: center;
    background-size: contain;
}
img.phoneImgHeight {
    height: 633px;
}
.signUpBannerImg{
    background-image: url(/src/assets/images/HinduBannerImg.jpg);
    height: 100vh;
    width: 100%;
    background-size: cover;
}

/* sign-in/sign-up style*/
.signtabsStyle{
    background: #fff;
    width: 100%;
    max-width: 480px;
    border-radius: 20px;
}

.hr-text {
    width: 50px;
    margin: auto;
    background: #f4f5f8; /* Assuming --ion-color-light was a light color */
    margin-top: -18px;
    text-align: center;
    color: #000;
    font-weight: 600;
  }
  
  .hr-border-red {
    height: 1px;
    width: 100%;
    margin-top: -18px;
    background: linear-gradient(to right, #f4f5f8 0%, red 26%, red 52%, red 75%, #f4f5f8 100%);
    margin: 2.5px 0px;
  }
  
  .hr-border-yellow {
    height: 1px;
    width: 100%;
    margin-top: -18px;
    background: linear-gradient(to right, #f4f5f8 0%, #ffcc00 26%, #ffcc00 52%, #ffcc00 75%, #f4f5f8 100%);
    margin: 2.5px 0px;
  }
  
  .hr-border-green {
    height: 1px;
    width: 100%;
    margin-top: -18px;
    background: linear-gradient(to right, #f4f5f8 0%, green 26%, green 52%, green 75%, #f4f5f8 100%);
    margin: 2.5px 0px;
  }
  
  .hr-border-blue {
    height: 1px;
    width: 100%;
    margin-top: -18px;
    background: linear-gradient(to right, #f4f5f8 0%, blue 26%, blue 52%, blue 75%, #f4f5f8 100%);
    margin: 2.5px 0px;
  }


  button.MuiButtonBase-root.MuiButton-root.btnOutline {
    background: rgba(239, 66, 59, 0.2) !important;
    color: #000;
    border: 1px red solid !important;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    box-shadow: none;
    text-transform: capitalize;
}
.passwordEyeicon {
    position: absolute;
    top: 10px;
    right: 0;
}


.button-27 {
  appearance: none;
  background-color: #f66e06;
  border: none;
  border-radius: 15px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.button-27:disabled {
  pointer-events: none;
}

.button-27:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-27:active {
  box-shadow: none;
  transform: translateY(0);
}

/* DownloadButton.css */
.btn-27{
    border: none;
    background: #f66e06;
    color: white;
    font-weight: 600;
}
  
  .circle-progress {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid white;
    border-top-color: white;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 10%;
    left: 25%;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: bold;
  }
  
  .hidden {
    display: none;
  }
  