.displayEndProp{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.displayCenterProp{
    display: flex;
    justify-content: center;
    align-items: center;
}
.primaryBg{
background: var(--Safron, #F66E06);
}
.secondaryBg{
background: var(--white, #FFF);
}
.light{
    color: var(--white, #fff);
}
.primaryColor{
    color: var(--Safron, #F66E06);;
}
body {
    margin: 0px;
    font-family: 'Poppins', sans-serif !important;
}

.fs-12{
    font-size: 12px;
}
.fs-15{
    font-size: 15px;
}
.fs-16{
    font-size: 16px ;
}
.fs-18{
    font-size: 18px !important;
}
.fs-20{
    font-size:20px;
}
.fs-22{
    font-size: 22px;
}
.fs-24{
    font-size: 24px !important;
}
.fs-26{
    font-size: 26px !important;
}
.fs-28{
    font-size: 28px;
}
.fs-32{
    font-size: 32px;
}
.fs-30{
    font-size: 30px;
}
.fs-32{
    font-size: 32px;
}
.fs-38{
    font-size: 38px !important;
}
.fs-40{
    font-size: 40px;
}
.fs-48{
    font-size: 48px;
}
.fs-45{
    font-size: 45px !important;
}
.fs-50{
    font-size: 50px;
}
iframe {
    border-radius: 20px;
}
.fw-500{
    font-weight: 500;
}
.fw-700{
    font-weight: 700;
}
.iconStyle {
    background: #fff;
    border-radius: 50%;
    height: 75px;
    width: 75px;
    padding: 15px;
}

